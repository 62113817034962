import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import Philanthropy from '../components/Philanthropy';

// import PageTransition from 'gatsby-plugin-page-transitions';



export default class IndexPage extends React.Component {

  render() {
    const { data } = this.props
    const { edges } = data.allMarkdownRemark
    const investments = edges.filter(({node: post}) => {if (post.frontmatter && post.frontmatter.templateKey == 'investment') return post;} );
    const properties = edges.filter(({node: post}) => {if (post.frontmatter && post.frontmatter.templateKey == 'properties') return post;} );
    const charity = edges.filter(({node: post}) => {if (post.frontmatter && post.frontmatter.templateKey == 'charity') return post;} );

   


    console.log(charity);
    // const  realestates = edges.filter(post => {if (post.frontmatter.templateKey == 'properties') return post; });
    return (

      <Layout>
        <section className="hero is-primary sand-bg">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-mobile">
                <div className="column is-tablet is-offset-1-tablet">
                  <h1 className="title is-2 is-spaced">
                    Blue Sand Holdings
                  </h1>
                  <p className="is-size-4 is-spaced">
                    Providing Capital and Business Expertise to Private Companies and Real Estate Ventures
                  </p>
                  {/* <hr/> */}
                  <p className="is-size-5 is-spaced is-italic">
                    Investing in Innovation 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="private_equity" className="section">
            <div className="title is-size-4 has-text-centered">
                <h2>Private Equity</h2>
              Funding and Growing Innovative Private Companies
            </div>
            <div className="columns is-mobile is-centered is-vcentered is-multiline">
              {investments.map(({node:post}) => (

                <div key={post.id} className="column is-half-mobile is-one-third-tablet is-one-third-desktop is-one-quarter-widescreen is-vcentered investment" key={post.id}>
                  <a className="has-text-centered" target="_blank" href={ post.frontmatter.websiteURL }>
                    <figure  className="image has-text-centered box is-centered is-vcentered is-vertical-center">
                    
                      <img 
                          src={post.frontmatter.logo.publicURL} 
                          width={post.frontmatter.logoWidth}
                          height={post.frontmatter.logoHeight}></img>
                  
                    </figure>
                  </a>
                </div>
              ))
              }
            </div>
        </section>
        <section id="real_estate" className="section">
            <div className="title is-size-4 has-text-centered">
              <h2>Real Estate</h2>
              <p>
                Working with Strong, Proven, Local Entrepreneurs to Create Value through Innovation
              </p>
            </div>
            <div className="columns is-mobile is-multiline">
              {properties.map(({node:post}) => (
                <div className="column is-full-mobile is-one-third-tablet" key={post.id}>
                  <article className={"box properties is-vcentered " + post.frontmatter.category } >
                    <div className="content">
                      <p className="is-size-9">{ post.frontmatter.description }</p>
                      <p className="is-size-7 subtitle">{ post.frontmatter.name }</p>
                    </div>
                  </article>
                </div>
              ))}
            </div>    
        </section>

        <section id="giving_back" className="section">
          <Philanthropy />
        </section>

        <section id="contact" className="hero">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-mobile">
                <div className="column is-tablet is-offset-1-tablet">
                  <h1 className="title is-2 is-spaced">
                    Contact
                  </h1>
                  <p className="is-size-4 is-spaced">
                    Las Vegas
                  </p>
                  <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bsh-honey">
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="bsh-honey" /> 
                    <div class="field">
                      <div class="control">
                        <input name="email" class="input" type="email" placeholder="email address"/>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <textarea name="message" class="textarea" placeholder="message" rows="5"></textarea>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <button class="button is-link">Send</button>
                      </div>
                    </div> 
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    })
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
   allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___name] },
      filter: { frontmatter: {templateKey: {in: ["investment","properties","charity"]}} }
    ) 
    {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            name
            category
            image { publicURL }
            title
            heading
            intro {
              blurbs {
                text
              }
            }
            description
            logo {
              publicURL
            }
            websiteURL
            logoWidth
            logoHeight
          }
        }
      }
    }
  }
`
