import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Content, { HTMLContent } from '../components/Content'



const Philanthropy = () => (
    <StaticQuery
    query={graphql`query {
          allMarkdownRemark(
           limit: 1
           sort: { order: ASC, fields: [frontmatter___name] },
           filter: { frontmatter: {templateKey: {in: ["philanthropy"]}} }
          ) 
         {
           edges {
             node {
               id
               html
               fields {
                 slug
               }
               frontmatter {
                 templateKey
                 image { publicURL }
                 title
                 heading
                 
               }
              }
            }
         }
       }
     `}
    render={(data) => (
      // console.log(data)
      data.allMarkdownRemark.edges.map(({node: post}) => {
        return (
        <div className="columns">
            <div key={post.id} className="column">
                <section className="section">
                <h4 className="has-text-centered has-text-weight-semibold">
                    {post.frontmatter.heading}
                </h4>
                <h2 className="is-size-1 has-text-weight-bold has-text-primary has-text-centered">
                    {post.frontmatter.title}
                </h2>
               <HTMLContent content={post.html} />

                </section>
            </div>
        </div>
      )
      })
    )}
  />
)

Philanthropy.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              header: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
          }),
        })
      ),
    }),
  }),
}

export default Philanthropy
